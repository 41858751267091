<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Game Logs</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="searchTypeSelect"
        :items="searchTypeItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Search Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="timeSelect"
        :items="timeItems"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Mode"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="typeSelect"
        :items="gameType"
        :loading="$store.state.progress"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headersOutPlayer"
      :items="$store.state.gameLogs.all.data"
      :items-per-page="pageItemSelect.id"
      hide-default-footer
      :loading="loading"
      :options.sync="options"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-btn color="green" dark @click="detailPopup(item)" class="mb-2"
          >Detail</v-btn
        >
        <v-btn color="primary" dark @click="editItem(item)" class="mb-2"
          >Json</v-btn
        >
      </template>
      <template v-slot:item.id="{ item }">
        <div style="width:210px;">
          <div>{{ item.s }}</div>
          <div>{{ item.id }}</div>
          <div>{{ new Date(item.v[0]) | formatDateSS }}</div>
          <div>{{ new Date(item.v[1]) | formatDateSS }}</div>
          <div>{{ (item.v[2] / 1000) | getDateFormat }}</div>
          <div>{{ item.v[3] }}</div>
          <div>{{ item.v[4] }}</div>
        </div>
      </template>
      <template v-slot:item.w="{ item }">
        <div :style="`color:${item.w ? 'red' : 'lime'}`">{{ item.w }}</div>
      </template>
      <template v-slot:item.tx="{ item }">
        {{ item.v[5] }}
      </template>
      <template v-slot:item.j="{ item }">
        {{ item.v[8] }}
      </template>
      <template v-slot:item.g="{ item }">
        {{ item.v[7] }}
      </template>

      <template v-slot:item.different="{ item }">
        <!-- {{ getDifferent(item.ss) }} - -->
        <div v-if="item.v[11] != 0">
          <v-icon x-large class="mr-2" color="red">mdi-alert</v-icon>
          <b color="red"> {{ item.v[11] | formatMoney }}</b>
        </div>
      </template>
      <template v-slot:item.tp="{ item }">
        {{ item.v[6] }}
      </template>
      <template v-slot:item.c="{ item }">
        <div style="min-width:150px" v-if="item.c">
          <div
            v-for="(c, index) in item.c.split(',')"
            :key="index"
            style="width: 27px;
    height: 40px; display:inline-block"
          >
            <div v-if="c" :class="`playerCards card${c}`" value.a></div>
          </div>
          <div v-if="item.v[11] != 0" style="text-align: center;">
            <v-icon x-large class="mr-2" color="red">mdi-alert</v-icon>
            <b color="red"> {{ item.v[11] }}</b>
          </div>
        </div>
      </template>
      <template v-slot:item.d="{ item }">
        <div
          style="min-width:600px;"
          v-for="(value, index) in item.d"
          :key="index"
        >
          <div>
            <b style="color:yellow">Time: </b
            >{{ new Date(value.t) | formatDate }}
          </div>
          <div><b style="color:yellow">LogType: </b>{{ value.vt }}</div>
          <div><b style="color:yellow">Data: </b> {{ value.v }}</div>
        </div>
      </template>
      <template v-slot:item.t="{ item }">
        <template>
          {{
            gameType.find((a) => a.key == item.t)
              ? gameType.find((a) => a.key == item.t).value
              : item.t
          }}
        </template>
      </template>
      <template v-slot:item.ss="{ item }">
        <div style="display: flex;">
          <div
            v-for="(value, index) in item.ss"
            :key="index"
            :style="`position:relative;${item.t == 11 ? 'width:120px;' : ''}`"
            :class="
              `card-p ${
                value.v[3] > 0 ? 'lime' : value.v[3] == 0 ? 'orange' : 'red'
              }-card`
            "
          >
            <div style="text-align: center;font-weight: bold;">
              {{ value.v[3] | formatMoney }}
            </div>
            <v-avatar size="58" style="margin:0 5px;  ">
              <v-img
                v-if="value.pu && value.pu.startsWith('http')"
                :src="`${value.pu}`"
              />
              <v-img
                v-else-if="value.pu && value.pu.startsWith('/')"
                :src="`https://pokerface.restplay.com${value.pu}`"
              />
              <v-img
                v-else
                :src="`https://pokerface.restplay.com/Avatars/${value.pu}`"
              />
            </v-avatar>
            <div
              v-if="value.c[0]"
              :style="
                `position:absolute; top:-${getPosition(
                  0
                )}px; left:20px; width:108px; z-index:1`
              "
            >
              <div
                v-for="(c, index) in value.c[0].split(',')"
                :key="index"
                style="width: 27px;height: 40px; display:inline-block"
              >
                <div
                  :class="`playerCards card${c}`"
                  :style="`opacity:${value.v[6] ? 1 : 0.6}`"
                ></div>
              </div>
            </div>
            <div
              style="text-align: center;font-weight: bold; font-size:15px;text-overflow: ellipsis;white-space: nowrap; 
  overflow: hidden;"
            >
              {{ value.u }}
            </div>
            <div
              style="text-align:center;font-weight: bold;color:yellow;font-size:15px;"
            >
              {{ value.l }}
            </div>
            <div style="text-align:center;">
              {{ value.i }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.user="{ item }">
        <div v-if="item.user" style="display: flex;">
          <div
            style="position:relative"
            :class="
              `card-p ${
                item.user.p > 0 ? 'lime' : item.user.p == 0 ? 'orange' : 'red'
              }-card`
            "
          >
            <div style="text-align: center;font-weight: bold;">
              {{ item.user.p | formatMoney }}
            </div>
            <v-avatar size="58" style="margin:0 5px;  ">
              <v-img
                v-if="item.user.pu && item.user.pu.startsWith('http')"
                :src="`${item.user.pu}`"
              />
              <v-img
                v-else-if="item.user.pu && item.user.pu.startsWith('/')"
                :src="`https://pokerface.restplay.com${item.user.pu}`"
              />
              <v-img
                v-else
                :src="`https://pokerface.restplay.com/Avatars/${item.user.pu}`"
              />
            </v-avatar>
            <div
              v-for="(cc, ind) in item.user.c"
              :style="
                `position:absolute; top:-${getPosition(
                  ind
                )}px; left:20px; width:170px; z-index:1`
              "
              :key="ind"
            >
              <template v-if="cc">
                <div
                  v-for="(c, index) in cc
                    .replace(' ', ',')
                    .replace(' ', ',')
                    .replace(' ', ',')
                    .split(',')"
                  :key="ind + '-' + index"
                  style="width: 27px;
    height: 40px; display:inline-block"
                >
                  <div
                    :class="`playerCards card${c}`"
                    :style="`opacity:${item.user.a ? 1 : 0.6}`"
                  ></div>
                </div>
              </template>
            </div>
            <div
              style="text-align: center;font-weight: bold; font-size:15px;text-overflow: ellipsis;white-space: nowrap; 
  overflow: hidden;"
            >
              {{ item.user.u }}
            </div>
            <div
              style="text-align:center;font-weight: bold;color:yellow;font-size:15px;"
            >
              {{ item.user.l }}
            </div>
            <div style="text-align:center;">
              {{ item.user.i }}
            </div>
          </div>
        </div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="$store.state.gameLogs.all.totalPage"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="playerIOdialog" max-width="1800px">
        <v-card v-if="playerIOdialog">
          <v-card-title>
            <span class="headline">Game Details</span>
          </v-card-title>
          <div v-for="(c, ind) in $store.state.gameLogs.detail[0].d" :key="ind">
            <b style="color:yellow;margin-left:10px"
              >[{{ new Date(c.t) | formatDateSSS }}]
            </b>
            <b style="color:lime;">
              {{ logType.find((m) => m.key == c.vt).value }} : </b
            >{{ c.v }}
          </div>
          <v-jsoneditor
            v-model="$store.state.gameLogs.detail"
            :options="jsonOptions"
            :plus="true"
            height="800px"
          />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="playerIOdialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="holdemDialog" max-width="1800px">
        <v-card>
          <v-data-table
            v-if="holdemDialog"
            :headers="headerHoldem"
            :items="detail.ss"
            :disable-sort="true"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template v-slot:header.i="{ item }">
              <div><b>id:</b> {{ detail.id }}</div>
              <div>
                <b>Start Time: </b>{{ new Date(detail.v[0]) | formatDateSS }}
              </div>
              <div>
                <b>End Time:</b> {{ new Date(detail.v[1]) | formatDateSS }}
              </div>
              <div>
                <b>Play Time:</b> {{ (detail.v[2] / 1000) | getDateFormat }}
              </div>
              <div><b>Type:</b> {{ detail.t }}</div>
              <div><b>Jackpot:</b> {{ detail.v[8] | formatMoney }}</div>
              <div><b>Tax:</b> {{ detail.v[5] | formatMoney }}</div>
            </template>
            <template v-slot:header.pre-flop="{ header }">
              <flop-card-header text="PRE-FLOP" :cards="[]" />
            </template>
            <template v-slot:header.flop="{ header }">
              <flop-card-header
                text="FLOP"
                :cards="detail.c.split(',').slice(0, 3)"
              />
            </template>
            <template v-slot:header.turn="{ header }">
              <flop-card-header
                text="TURN"
                :cards="
                  detail.c.split(',').length >= 4
                    ? detail.c.split(',').slice(0, 4)
                    : []
                "
              />
            </template>
            <template v-slot:header.river="{ header }">
              <flop-card-header
                text="RİVER"
                :cards="
                  detail.c.split(',').length == 5 ? detail.c.split(',') : []
                "
              />
            </template>

            <template v-slot:header.winner="{ header }">
              <flop-card-header text="WİNNERS" :cards="[]" />
            </template>
            <template v-slot:item.pre-flop="{ item }">
              <flop-card-action :value="item.v[7]" :action="item.v[11]" />
            </template>
            <template v-slot:item.flop="{ item }">
              <flop-card-action :value="item.v[8]" :action="item.v[12]" />
            </template>
            <template v-slot:item.turn="{ item }">
              <flop-card-action :value="item.v[9]" :action="item.v[13]" />
            </template>
            <template v-slot:item.river="{ item }">
              <flop-card-action :value="item.v[10]" :action="item.v[14]" />
            </template>
            <template v-slot:item.winner="{ item }">
              <div style="text-align:center;">
                <h2>
                  {{ handName.find((c) => c.key == item.v[5]).value }}
                </h2>
                <h3 :style="item.v[3] > 0 ? 'color:lime' : 'color:#F44336'">
                  {{ item.v[3] | formatMoney }}
                </h3>
              </div>
            </template>
            <template v-slot:item.i="{ item }">
              <div style="position:relative;padding-left:50px;">
                <UserDetailCard
                  :userId="item.i"
                  :pictureUrl="item.pu"
                  :userName="item.u"
                  :level="item.l"
                />
                <div
                  v-if="item.v[4] > 0"
                  :style="
                    `width:196px;height:140px;top: 3px;left: 0px;position: absolute;background-size: 83px; background-image:  url(${require('../../../assets/img/jackpot.png')};`
                  "
                >
                  <div style="margin-top: 6px;padding-left: 38px;">
                    {{ item.v[4] }}
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:item.c="{ item }">
              <div v-if="item.c" style="width:370px;position: relative;">
                <div
                  style="padding-right:30px;position: absolute;
    top: -79px; z-index:1"
                >
                  <div
                    v-for="(cc, indexx) in item.c"
                    style="padding-right:20px;display:inline-block"
                    :key="indexx"
                  >
                    <template v-if="cc">
                      <div
                        v-for="(c, index) in cc
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .split(',')"
                        style="width: 27px;
    height: 40px; display:inline-block"
                        :key="indexx + index + +99"
                      >
                        <div
                          v-if="c"
                          :class="`playerCards card${c}`"
                          value.a
                        ></div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="holdemDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="trioDialog" max-width="1800px">
        <v-card>
          <v-data-table
            :headers="headerTrio"
            v-if="trioDialog"
            :items="detail.ss"
            :disable-sort="true"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template v-slot:header.i="{ item }">
              <div><b>id:</b> {{ detail.id }}</div>
              <div>
                <b>Start Time: </b>{{ new Date(detail.v[0]) | formatDateSS }}
              </div>
              <div>
                <b>End Time:</b> {{ new Date(detail.v[1]) | formatDateSS }}
              </div>
              <div>
                <b>Play Time:</b> {{ (detail.v[2] / 1000) | getDateFormat }}
              </div>
              <div><b>Type:</b> {{ detail.t }}</div>
              <div><b>Jackpot:</b> {{ detail.v[8] | formatMoney }}</div>
              <div><b>Tax:</b> {{ detail.v[5] | formatMoney }}</div>
            </template>
            <template v-slot:header.ante="{ header }">
              <flop-card-header text="ANTE" :cards="[]" />
            </template>
            <template v-slot:header.play="{ header }">
              <flop-card-header text="PLAY" :cards="[]" />
            </template>
            <template v-slot:header.pair="{ header }">
              <flop-card-header text="PAIR" :cards="[]" />
            </template>
            <template v-slot:header.three="{ header }">
              <flop-card-header text="3+3" :cards="[]" />
            </template>

            <template v-slot:header.winner="{ header }">
              <flop-card-header text="WİNNERS" :cards="[]" />
            </template>
            <template v-slot:item.ante="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[7]"
                :action="item.v[11]"
              />
            </template>
            <template v-slot:item.pair="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[8]"
                :action="item.v[12]"
              />
            </template>
            <template v-slot:item.three="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[9]"
                :action="item.v[13]"
              />
            </template>
            <template v-slot:item.play="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[10]"
                :action="item.v[14]"
              />
            </template>
            <template v-slot:item.winner="{ item }">
              <div
                v-if="detail.trio && item.i == 'Dealer'"
                style="text-align:center;"
              >
                <h2>
                  {{ handNameMini.find((c) => c.key == detail.v[10]).value }}
                </h2>
                <h3 :style="detail.v[9] > 0 ? 'color:lime' : 'color:#F44336'">
                  {{ detail.v[9] | formatMoney }}
                </h3>
              </div>
              <div v-if="item.i != 'Dealer'" style="text-align:center;">
                <h2>
                  {{ handNameMini.find((c) => c.key == item.v[5]).value }}
                </h2>
                <h3 :style="item.v[3] > 0 ? 'color:lime' : 'color:#F44336'">
                  {{ item.v[3] | formatMoney }}
                </h3>
              </div>
            </template>
            <template v-slot:item.i="{ item }">
              <div style="position:relative;padding-left:50px;">
                <UserDetailCard
                  :userId="item.i"
                  :pictureUrl="item.pu"
                  :userName="item.u"
                  :level="item.l"
                />
                <div
                  v-if="item.v[4] > 0"
                  :style="
                    `width:196px;height:140px;top: 3px;left: 0px;position: absolute;background-size: 83px; background-image:  url(${require('../../../assets/img/jackpot.png')};`
                  "
                >
                  <div style="margin-top: 6px;padding-left: 38px;">
                    {{ item.v[4] }}
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:item.c="{ item }">
              <div v-if="item.c" style="width:270px;position: relative;">
                <div
                  style="padding-right:30px;position: absolute;
    top: -79px;"
                >
                  <div
                    v-for="(cc, indexx) in item.c"
                    :key="indexx"
                    style="padding-right:20px;display:inline-block"
                  >
                    <template v-if="cc">
                      <div
                        v-for="(c, index) in cc
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .split(',')"
                        style="width: 27px;
    height: 40px; display:inline-block"
                        :key="indexx + index + +99"
                      >
                        <div v-if="c" :class="`playerCards card${c}`"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="trioDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="ultimateDialog" max-width="1800px">
        <v-card>
          <v-data-table
            :headers="headerUltimate"
            v-if="ultimateDialog"
            :items="detail.ss"
            :disable-sort="true"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template v-slot:header.i="{ item }">
              <div><b>id:</b> {{ detail.id }}</div>
              <div>
                <b>Start Time: </b>{{ new Date(detail.v[0]) | formatDateSS }}
              </div>
              <div>
                <b>End Time:</b> {{ new Date(detail.v[1]) | formatDateSS }}
              </div>
              <div>
                <b>Play Time:</b> {{ (detail.v[2] / 1000) | getDateFormat }}
              </div>
              <div><b>Type:</b> {{ detail.t }}</div>
              <div><b>Jackpot:</b> {{ detail.v[8] | formatMoney }}</div>
              <div><b>Tax:</b> {{ detail.v[5] | formatMoney }}</div>
            </template>
            <template v-slot:header.c="{ header }">
              <div v-if="detail.c" style="width:270px;position: relative;">
                <div
                  style="padding-right:30px;position: absolute;
    top: -79px;"
                >
                  <div
                    v-for="(c, indexx) in detail.c
                      .replace(' ', ',')
                      .replace(' ', ',')
                      .replace(' ', ',')
                      .replace(' ', ',')
                      .split(',')"
                    :key="indexx"
                    style="padding-right:0px;display:inline-block"
                  >
                    <template v-if="c">
                      <div
                        style="width: 27px;    height: 40px; display:inline-block"
                        :key="indexx + index + +99"
                      >
                        <div v-if="c" :class="`playerCards card${c}`"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:header.ante="{ header }">
              <flop-card-header text="ANTE" :cards="[]" />
            </template>
            <template v-slot:header.play="{ header }">
              <flop-card-header text="PLAY" :cards="[]" />
            </template>
            <template v-slot:header.trips="{ header }">
              <flop-card-header text="TRIPS" :cards="[]" />
            </template>
            <template v-slot:header.blind="{ header }">
              <flop-card-header text="BLIND" :cards="[]" />
            </template>

            <template v-slot:header.winner="{ header }">
              <flop-card-header text="WİNNERS" :cards="[]" />
            </template>
            <template v-slot:item.ante="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[7]"
                :action="item.v[11]"
              />
            </template>
            <template v-slot:item.blind="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[8]"
                :action="item.v[12]"
              />
            </template>
            <template v-slot:item.trips="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[9]"
                :action="item.v[13]"
              />
            </template>
            <template v-slot:item.play="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[10]"
                :action="item.v[14]"
              />
            </template>
            <template v-slot:item.winner="{ item }">
              <div
                v-if="detail.trio && item.i == 'Dealer'"
                style="text-align:center;"
              >
                <h2>
                  {{ handNameMini.find((c) => c.key == detail.v[10]).value }}
                </h2>
                <h3 :style="detail.v[9] > 0 ? 'color:lime' : 'color:#F44336'">
                  {{ detail.v[9] | formatMoney }}
                </h3>
              </div>
              <div v-if="item.i != 'Dealer'" style="text-align:center;">
                <h2>
                  {{ handNameMini.find((c) => c.key == item.v[5]).value }}
                </h2>
                <h3 :style="item.v[3] > 0 ? 'color:lime' : 'color:#F44336'">
                  {{ item.v[3] | formatMoney }}
                </h3>
              </div>
            </template>
            <template v-slot:item.i="{ item }">
              <div style="position:relative;padding-left:50px;">
                <UserDetailCard
                  :userId="item.i"
                  :pictureUrl="item.pu"
                  :userName="item.u"
                  :level="item.l"
                />
                <div
                  v-if="item.v[4] > 0"
                  :style="
                    `width:196px;height:140px;top: 3px;left: 0px;position: absolute;background-size: 83px; background-image:  url(${require('../../../assets/img/jackpot.png')};`
                  "
                >
                  <div style="margin-top: 6px;padding-left: 38px;">
                    {{ item.v[4] }}
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:item.c="{ item }">
              <div v-if="item.c" style="width:270px;position: relative;">
                <div
                  style="padding-right:30px;position: absolute;
    top: -79px;"
                >
                  <div
                    v-for="(cc, indexx) in item.c"
                    :key="indexx"
                    style="padding-right:20px;display:inline-block"
                  >
                    <template v-if="cc">
                      <div
                        v-for="(c, index) in cc
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .split(',')"
                        style="width: 27px;
    height: 40px; display:inline-block"
                        :key="indexx + index + +99"
                      >
                        <div v-if="c" :class="`playerCards card${c}`"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="trioDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="highCardFlushDialog" max-width="1800px">
        <v-card>
          <v-data-table
            :headers="highCardFlush"
            v-if="highCardFlushDialog"
            :items="detail.ss"
            :disable-sort="true"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1"
          >
            <template v-slot:header.i="{ item }">
              <div><b>id:</b> {{ detail.id }}</div>
              <div>
                <b>Start Time: </b>{{ new Date(detail.v[0]) | formatDateSS }}
              </div>
              <div>
                <b>End Time:</b> {{ new Date(detail.v[1]) | formatDateSS }}
              </div>
              <div>
                <b>Play Time:</b> {{ (detail.v[2] / 1000) | getDateFormat }}
              </div>
              <div><b>Type:</b> {{ detail.t }}</div>
              <div><b>Jackpot:</b> {{ detail.v[8] | formatMoney }}</div>
              <div><b>Tax:</b> {{ detail.v[5] | formatMoney }}</div>
            </template>

            <template v-slot:header.ante="{ header }">
              <flop-card-header text="ANTE" :cards="[]" />
            </template>
            <template v-slot:header.raise="{ header }">
              <flop-card-header text="RAISE" :cards="[]" />
            </template>
            <template v-slot:header.flush="{ header }">
              <flop-card-header text="FLUSH" :cards="[]" />
            </template>
            <template v-slot:header.sflush="{ header }">
              <flop-card-header text="S.FLUSH" :cards="[]" />
            </template>

            <template v-slot:header.winner="{ header }">
              <flop-card-header text="WİNNERS" :cards="[]" />
            </template>
            <template v-slot:item.ante="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[7]"
                :action="item.v[11]"
              />
            </template>
            <template v-slot:item.flush="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[8]"
                :action="item.v[12]"
              />
            </template>
            <template v-slot:item.sflush="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[9]"
                :action="item.v[13]"
              />
            </template>
            <template v-slot:item.raise="{ item }">
              <trio-card-action
                :winChip="item.v[3]"
                :value="item.v[10]"
                :action="item.v[14]"
              />
            </template>
            <template v-slot:item.winner="{ item }">
              <div
                v-if="detail.trio && item.i == 'Dealer'"
                style="text-align:center;"
              >
                <h2>
                  {{ handNameMini.find((c) => c.key == detail.v[10]).value }}
                </h2>
                <h3 :style="detail.v[9] > 0 ? 'color:lime' : 'color:#F44336'">
                  {{ detail.v[9] | formatMoney }}
                </h3>
              </div>
              <div v-if="item.i != 'Dealer'" style="text-align:center;">
                <h2>
                  {{ handNameMini.find((c) => c.key == item.v[5]).value }}
                </h2>
                <h3 :style="item.v[3] > 0 ? 'color:lime' : 'color:#F44336'">
                  {{ item.v[3] | formatMoney }}
                </h3>
              </div>
            </template>
            <template v-slot:item.i="{ item }">
              <div style="position:relative;padding-left:50px;">
                <UserDetailCard
                  :userId="item.i"
                  :pictureUrl="item.pu"
                  :userName="item.u"
                  :level="item.l"
                />
                <div
                  v-if="item.v[4] > 0"
                  :style="
                    `width:196px;height:140px;top: 3px;left: 0px;position: absolute;background-size: 83px; background-image:  url(${require('../../../assets/img/jackpot.png')};`
                  "
                >
                  <div style="margin-top: 6px;padding-left: 38px;">
                    {{ item.v[4] }}
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:item.c="{ item }">
              <div v-if="item.c" style="width:270px;position: relative;">
                <div
                  style="padding-right:30px;position: absolute;
    top: -79px;"
                >
                  <div style="padding-right:20px;display:inline-block">
                    <template v-if="item.c[0]">
                      <div
                        v-for="(c, index) in item.c[0]
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .replace(' ', ',')
                          .split(',')"
                        style="width: 27px;
    height: 40px; display:inline-block"
                        :key="indexx + index + +99"
                      >
                        <div v-if="c" :class="`playerCards card${c}`"></div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="trioDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import VJsoneditor from "v-jsoneditor";
import "@/assets/css/site.css";
import FlopCardHeader from "./flop-card-header.vue";
import handName from "@/lib/handName";
import handNameMini from "@/lib/handNameMini";
import logType from "@/lib/logType";
import actionType from "@/lib/actionType";
import gameType from "@/lib/gameType";
import FlopCardAction from "./flop-card-action.vue";
import TrioCardAction from "./trio-card-action.vue";

export default {
  components: {
    VJsoneditor,
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
    FlopCardHeader,
    FlopCardAction,
    TrioCardAction,
  },

  name: "GameLogListCard",
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      playerIOdialog: false,
      pageCount: 0,
      timeSelect: { id: -1, value: "All" },
      typeSelect: { key: -999, value: "All" },
      searchTypeSelect: { id: -1, value: "All" },
      pageItemSelect: { id: 50, value: "50" },
      loading: false,
      holdemDialog: false,
      highCardFlushDialog: false,
      trioDialog: false,
      ultimateDialog: false,
      currentPage: 1,
      actionType: actionType,
      gameType: gameType,
      handName: handName,
      handNameMini: handNameMini,
      logType: logType,
      options: {},
      detail: {},
      jsonOptions: {
        mode: "code",
      },
      pagination: {
        sortBy: "name",
      },
      searchTypeItems: [
        { id: -1, value: "All" },
        { id: 0, value: "RoomId" },
        { id: 1, value: "Server" },
      ],
      timeItems: [
        { id: -1, value: "All" },
        { id: 60 * 30, value: "Last 30 minutes" },
        { id: 60 * 60, value: "Last 1 hour" },
        { id: 60 * 60 * 24, value: "Last 24 hours" },
        { id: 60 * 60 * 24 * 7, value: "Last 7 Days" },
        { id: 60 * 60 * 24 * 30, value: "last 30 Days" },
        { id: 60 * 60 * 24 * 90, value: "last 90 Days" },
      ],

      pageItems: [
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
      ],
      headerHoldem: [
        { text: "user", value: "i" },
        { text: "", value: "c" },
        { text: "Pre-flop", value: "pre-flop" },
        { text: "Flop", value: "flop" },
        { text: "Turn", value: "turn" },
        { text: "River", value: "river" },
        { text: "Winner", value: "winner" },
      ],
      headerTrio: [
        { text: "user", value: "i" },
        { text: "", value: "c" },
        { text: "ante", value: "ante" },
        { text: "play", value: "play" },
        { text: "pair", value: "pair" },
        { text: "three", value: "three" },
        { text: "Winner", value: "winner" },
      ],
      highCardFlush: [
        { text: "user", value: "i" },
        { text: "", value: "c" },
        { text: "ante", value: "ante" },
        { text: "raise", value: "raise" },
        { text: "flush", value: "flush" },
        { text: "sflush", value: "sflush" },
        { text: "Winner", value: "winner" },
      ],

      headerUltimate: [
        { text: "user", value: "i" },
        { text: "", value: "c" },
        { text: "ante", value: "ante" },
        { text: "play", value: "play" },
        { text: "blind", value: "blind" },
        { text: "trips", value: "trips" },
        { text: "Winner", value: "winner" },
      ],
      headersOutPlayer: [
        { text: "id", value: "id" },
        { text: "RoomId", value: "r" },
        { text: "D.Winner", value: "w" },
        { text: "Tax", value: "tx" },
        { text: "Jackpot", value: "j" },
        { text: "Tip", value: "tp" },
        { text: "Type", value: "t" },
        { text: "Gifts", value: "g" },
        { text: "User", value: "user" },
        { text: "TableCards", value: "c" },
        { text: "Sits", value: "ss" },
        { text: "Actions", value: "action" },

        // { text: "Data", value: "d" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },

    async initialize() {
      this.loading = true;
      await this.$store.dispatch("gameLogs/getAll", {
        search: this.search,
        time: this.time,
        size: this.pageItemSelect.id,
        searchType: this.searchTypeSelect.id,
        roomType: this.typeSelect.key,
        page: this.currentPage - 1,
        date: this.timeSelect.id,
        options: this.options,
      });
      this.loading = false;
      // this.detail = this.$store.state.gameLogs.all.data[0];
      // this.holdemDialog = true;
    },
    getDifferent(item) {
      return item.v[5] + item.v[8] + item.ss.reduce((a, b) => a + b.v[3], 0);
    },
    async detailPopup(item) {
      // eslint-disable-next-line no-console
      console.log(item);
      this.detail = item;
      switch (item.t) {
        case 2:
          this.trioDialog = true;
          this.detail.trio = true;
          break;
        case 3:
          this.highCardFlushDialog = true;
          this.detail.trio = true;
          break;
        case 4:
          this.ultimateDialog = true;
          this.detail.trio = true;
          break;
        default:
          this.holdemDialog = true;
          break;
      }
    },
    async editItem(item) {
      await this.$store.dispatch("gameLogs/getDetail", item.id);
      this.playerIOdialog = true;
    },
    getPosition(ind) {
      switch (ind) {
        case 0:
          return 30;
        case 1:
          return 6;
      }
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>

<style>
.card-p {
  width: 142px;
  height: 155px;
  background-size: 142px 155px;
  background-repeat: no-repeat;
  border-radius: 9.5px;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.lime-card {
  background-image: url(https://pokerface.restplay.com/assets/green.png);
}
.green-card {
  background-image: url(https://pokerface.restplay.com/assets/green.png);
}
.red-card {
  background-image: url(https://pokerface.restplay.com/assets/red.png);
}
.orange-card {
  background-image: url(https://pokerface.restplay.com/assets/orange.png);
}
.v-tooltip__content {
  opacity: 1 !important;
}
.jv-container {
  background: #0000005c !important;
}
.jsoneditor-field {
  color: #b7b3c3 !important;
}
a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}
div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}
.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}
div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}
.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
