export default [
  { value: "SitJoined", key: 0 },
  { value: "ReSit", key: 1 },
  { value: "TopOff", key: 2 },
  { value: "ProfitOrLoss", key: 3 },
  { value: "SitOut", key: 4 },
  { value: "SpecJoined", key: 5 },
  { value: "UserLost", key: 6 },
  { value: "TurnChanged", key: 7 },
  { value: "DealHoles", key: 8 },
  { value: "MarkTurn", key: 9 },
  { value: "PlayerAction", key: 10 },
  { value: "OverBet", key: 11 },
  { value: "MakePot", key: 12 },
  { value: "MakeJackPot", key: 13 },
  { value: "TableCard", key: 14 },
  { value: "RestToRest", key: 15 },
  { value: "AllInWar", key: 16 },
  { value: "Winners", key: 17 },
  { value: "JackPotWinner", key: 18 },
  { value: "DefaultWinner", key: 19 },
  { value: "UpdateChips", key: 20 },
  { value: "ShowCards", key: 21 },
  { value: "SitState", key: 22 },
  { value: "SpinStart", key: 23 },
  { value: "BlindProgression", key: 24 },
  { value: "LevelUpInfo", key: 25 },
  { value: "Emoji", key: 26 },
  { value: "PublicMessage", key: 27 },
  { value: "MakeTax", key: 28 },
  { value: "DealerTip", key: 29 },
  { value: "RoomGift", key: 30 },
  { value: "TrioBetStart", key: 31 },
  { value: "TrioBet", key: 32 },
  { value: "TrioIReady", key: 33 },
  { value: "TrioBetStop", key: 34 },
  { value: "TrioChoiceStart", key: 35 },
  { value: "TrioChoice", key: 36 },
  { value: "TrioChoiceStop", key: 37 },
  { value: "TrioWinners", key: 38 }
]