export default [
  { value: "-", key: 0 },
  { value: "Fold", key: 5 },
  { value: "Check", key: 1 },
  { value: "Call", key: 2 },
  { value: "Raise", key: 3 },
  { value: "Allin", key: 4 },
  { value: "SmallBlind", key: 6 },
  { value: "BigBlind", key: 7 },
  { value: "NewSitBigBlind", key: 8 },
  { value: "Pot", key: 10 },
];
