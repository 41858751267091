<template>
  <div style="text-align:center;">
    <h2 v-if="action > 0" :style="`color:lime`">x{{ action / value }}</h2>
    <h2 v-if="action < 1" :style="`color:#ff6b6b`">x0</h2>
    <h4 v-if="value">
      {{ value | formatMoney }}
    </h4>
  </div>
</template>
<script>
import actionType from "@/lib/actionType";

export default {
  name: "trio-card-action",
  data() {
    return {
      actionType: actionType,
    };
  },
  props: {
    action: {
      type: Number,
    },
    value: {
      type: Number,
    },
    winChip: {
      type: Number,
    },
  },
  methods: {
    getColor() {
      switch (this.action) {
        case -1:
          return "#F44336";
        case 0:
          return "lime";
        case 1:
          return "#2196F3";
        default:
          break;
      }
    },
  },
};
</script>
