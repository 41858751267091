<template>
  <div style="text-align:center;">
    <h2 v-if="action" :style="`color:${getColor()}`">
      {{ actionType.find((c) => c.key == action).value }}
    </h2>
    <template v-if="value <= 0">
      <div v-if="action == 1">
        <v-icon style="font-weight:bold;color:#5aff7e" color="green" x-large
          >check</v-icon
        >
      </div>
      <div v-if="action == 2">
        <v-icon style="font-weight:bold;color:#54b3ff" x-large>check</v-icon>
      </div>
      <div v-if="action == 3">
        <v-icon style="font-weight:bold;color:#ffb647" x-large>check</v-icon>
      </div>
      <div v-if="action == 5">
        <v-icon style="font-weight:bold;color:#ff6b6b" x-large>close</v-icon>
      </div>
    </template>
    <h4 v-if="value && value > 0" :style="`color:${getColor()}`">
      {{ value | formatMoney }}
    </h4>
  </div>
</template>
<script>
import actionType from "@/lib/actionType";

export default {
  name: "trio-card-action",
  data() {
    return {
      actionType: actionType,
    };
  },
  props: {
    action: {
      type: Number,
    },
    value: {
      type: Number,
    },
  },
  methods: {
    getColor() {
      switch (this.action) {
        case 1:
          return "#5aff7e";
        case 2:
          return "#54b3ff";
        case 3:
          return "#ffb647";
        case 4:
          return "#ff5afa";
        case 5:
          return "#ff6b6b";
        default:
          break;
      }
    },
  },
};
</script>
