<template>
  <div
    :style="
      `width:196px;height:140px; background-image:  url(${require('../../../assets/img/panel.png')};`
    "
  >
    <div
      :style="
        `min-width:150px;position: relative; height:64px;margin-left: -22px;${getPadding()}`
      "
    >
      <div style="position: absolute;top: -42px;">
        <div
          v-for="(c, index) in cards"
          :key="index"
          style="width: 27px;
    height: 40px; display:inline-block"
        >
          <div
            v-if="c"
            :class="`playerCards card${c}`"
            :style="`${getBorder(index)}`"
            value.a
          ></div>
        </div>
      </div>
    </div>

    <h1 style="text-align: center;color:#89848a">{{ text }}</h1>
  </div>
</template>
<script>
export default {
  name: "flop-card-header",
  props: {
    cards: {
      type: Array,
    },
    text: {
      type: String,
    },
  },
  methods: {
    getPadding() {
      switch (this.cards.length) {
        case 3:
          return "padding-left:56px";
        case 4:
          return "padding-left:40px";
        case 5:
          return "padding-left:28px";
      }
    },
    getBorder(ind) {
      if (this.text == "TURN" && ind == 3) {
        return "border: solid 6px #0ea512;width: 77px;";
      }
      if (this.text == "RİVER" && ind == 4) {
        return "border: solid 6px #0ea512;width: 77px;";
      }
    },
  },
};
</script>
