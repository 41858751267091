export default [
  { value: "HIGH CARD", key: 0 },
  { value: "PAIR", key: 1 },
  { value: "TWO PAIR", key: 2 },
  { value: "TRIPS", key: 3 },
  { value: "STRAIGHT", key: 4 },
  { value: "FLUSH", key: 5 },
  { value: "FULL HOUSE", key: 6 },
  { value: "FOUR OF A KIND", key: 7 },
  { value: "STRAIGHT FLUSH", key: 8 },
  { value: "ROYAL FLUSH", key: 9 },
];