export default [
  { value: "Any", key: -999 },
  { value: "Elite", key: -2 },
  { value: "Holdem", key: 0 },
  { value: "Omaha", key: 1 },
  { value: "Trio", key: 2 },
  { value: "HighCardFlush", key: 3 },
  { value: "Ultimate", key: 4 },
  { value: "KingOfTable", key: 5 },
  { value: "KingOfOmaha", key: 6 },
  { value: "KingRestRoyal", key: 7 },
  { value: "KingRoyal", key: 8 },
  { value: "KingRest", key: 9 },
  { value: "Turbo", key: 10 },
  { value: "Royal", key: 11 },
  { value: "RestRoyal", key: 12 },
  { value: "Rest", key: 13 },
  { value: "Black", key: 14 },
  { value: "Red", key: 15 },
  { value: "Jump", key: 16 },
  { value: "Spin", key: 17 },
  { value: "Arena", key: 18 },
  { value: "BingoTexas", key: 19 },
  { value: "BingoOmaha", key: 20 },
  { value: "BingoTrio", key: 21 }
];
